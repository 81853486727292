var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"QvMYlqCkzUxj1Blym-B4G"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_APPLICATION_STAGE;

// if (SENTRY_ENVIRONMENT !== 'development')
//   Sentry.init({
//     dsn:
//       SENTRY_DSN ||
//       'https://af32f84dfaac0f0f79df73b94f1d5a44@o4504203371413504.ingest.sentry.io/4505662951391232',
//     environment: SENTRY_ENVIRONMENT,
//     // Adjust this value in production, or use tracesSampler for greater control
//     tracesSampleRate: 1,
//   });
